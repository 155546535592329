#site-content {
  flex: 1;
}

#site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.MuiTabs-root {
  min-height: 48px !important;
}

.rpv-default-layout-main {
  height: 100%;
}
